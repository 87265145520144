import React, {PureComponent} from 'react'
import {connect} from 'react-redux'

import {url} from '../../../../../../../../constants'
import adminInstance from '../../../../../../../instances/admininstance'
import Select from '../../../../../../../reuseComponent/Select/select-form'
import AsyncSelect from '../../../../../../../reuseComponent/Select/async-select'
import {getBidsValues} from '../../../../../../../../actions/detailsPo'
import {
    formatIntegerWithEmptyString,
    formatPriceWithEmptyString,
    formatPercentWithEmptyString
} from '../../../../../../../../reuseFunctions/formatValue'
import {Form} from 'react-bootstrap'
import Tooltip from '../../../../../../../reuseComponent/Tooltips/index'
import {createBid, removeUnconfirmedBid, editBid, changeState} from '../../../../../../../../actions/detailsPo'
import {calculateTotalBids, totalBidsToStore} from '../../../../../../../../reuseFunctions/calculateTotalBids'
import {setDetailsPurchaseAction} from '../../../../../../../../actions/purchase-orders'
import {notify} from '../../../../../../../reuseComponent/toast/index';
import {findPermInArray} from '../../../../../../../../reuseFunctions/checkPermission';
import {permEditInfo, permFinanceInfo} from '../../../../../../../../permissions/productOverview';
import {getArticlesRequest} from '../../../../../../../../services/articles'
import {sortArrayByObjectKey} from '../../../../../../../../reuseFunctions/reorder'
import CustomModalWithChildren from '../../../../../../../reuseComponent/modal/modalWithChildren'
import {getBrands} from '../../../../../../../../services/brands'

class EditedRow extends PureComponent {

    constructor(props) {
        super()
        if (typeof props.values === 'object') {
            this.state = {
                categories: [],
                values: {
                    commodity: props.values.commodity ? props.values.commodity : '',
                    gender: props.values.gender ? props.values.gender : '',
                    category: props.values.category ? props.values.category : '',
                    quality: props.values.quality ? props.values.quality : '',
                    channel: props.values.channel ? props.values.channel : '',
                    pricePerUnit: props.values.pricePerUnit !== null ? props.values.pricePerUnit : '',
                    creditNote: props.values.creditNote !== null ? props.values.creditNote : '',
                    quantity: props.values.quantity !== null ? props.values.quantity : '',
                    actualQuantity: props.values.actualQuantity !== null ? props.values.actualQuantity : '',
                    unit: props.values.unit ? props.values.unit : '',
                    packages: props.values.packages !== null ? props.values.packages : '',
                    pallets: props.values.pallets !== null ? props.values.pallets : '',
                    grossWeight: props.values.grossWeight !== null && props.values.grossWeight !== undefined && props.values.grossWeight !== "" ? props.values.grossWeight.toFixed(2) : '',
                    netWeight: props.values.netWeight !== null && props.values.netWeight !== undefined && props.values.netWeight !== "" ? props.values.netWeight.toFixed(2) : '',
                    totalPrice: props.values.totalPrice !== null ? props.values.totalPrice : '',
                    percent: props.values.percent ? props.values.percent : '',
                    articleId: props.values.articleEan ? {
                        label: `${props.values.articleEan} ${props.values.articleDescription}`,
                        value: props.values.articleEan,
                        isClear: true
                    } : '',
                    productName: props.values.productName ? props.values.productName : '',
                    brandId: props.values.brand ? {
                        label: props.values.brand.name,
                        value: props.values.brand.name,
                        id: props.values.brand.id
                    } : '',
                },
                idBid: props.values.id,
                isPricePerUnitValid: true,
                isCommodityValid: true,
                isGenderValid: true,
                isUnitValid: true,
                openConfirmAddBidModal: false,
            }
        } else {
            this.state = {
                categories: [],
                values: {}
            }
        }

    }

    getRelatedParams = (alias, param1, param2) => {
        adminInstance.get(`${url}/simple_parameter/${alias}/related_params`, {
            params: {
                param1: param1,
                param2: param2
            }
        })
            .then(res => {
                const {values} = this.props
                this.setState({
                    categories: res.data.map(category => ({
                        value: category.value,
                        label: category.value
                    }))
                }, () => {
                    if (values.category && this.state.categories.find(category => category.label === values.category) === undefined) {
                        this.setFieldValue('category', '')
                    }

                })
            })
    }

    getArticles = (inputValue) => {
        const option = {
            page: 0,
            limit: 100,
            search: inputValue
        }
        return new Promise(resolve => {
            getArticlesRequest(option).then(resp => {
                resolve(resp.data.articles.map(item => ({
                    label: `${item.ean} ${item.description}`,
                    value: item.id
                })))
            })
        })
    }

    setFieldValue = (field, value) => {
        this.setState(state => ({
            values: {
                ...state.values,
                [field]: value
            }
        }), () => {
            if (!this.state.values.pricePerUnit || Number(this.state.values.pricePerUnit) === 0) {
                this.setState({
                    isPricePerUnitValid: false,
                });
            } else {
                this.setState({
                    isPricePerUnitValid: true,
                });
            }

            if (this.state.values.gender.length === 0) {
                this.setState({
                    isGenderValid: false,
                });
            } else {
                this.setState({
                    isGenderValid: true,
                });
            }

            if (this.state.values.commodity.length === 0) {
                this.setState({
                    isCommodityValid: false,
                });
            } else {
                this.setState({
                    isCommodityValid: true,
                });
            }

            if (this.state.values.unit.length === 0) {
                this.setState({
                    isUnitValid: false,
                });
            } else {
                this.setState({
                    isUnitValid: true,
                });
            }


        });
    }

    handleCloseConfirmAddBidModal = () => {
        this.setState({
            openConfirmAddBidModal: false,
        });
    };

    createBid = () => {
        const {details, createBid, changeDetails, idUBid, removeBid, refetchData} = this.props

        const values = {
            ...this.state.values,
            ...calculateTotalBids(details.bids.concat(this.state.values)),
            idPurchase: details.id,
            brandId: this.state.values && this.state.values.brandId ? this.state.values.brandId.id : null,
        }
        const totalBids = calculateTotalBids(details.bids.concat(this.state.values))

        createBid && createBid({
            values: {...values, articleId: values.articleId.value ? values.articleId.value : ''},
            resolve: data => {
                refetchData && refetchData()
                changeDetails({
                    bids: details.bids.concat(data),
                    ...totalBidsToStore(totalBids)
                })
            }
        })
        removeBid(idUBid)
    }

    handleConfirmAddBidModal = () => {
        this.createBid();
        this.handleCloseConfirmAddBidModal();
    }

    handleSubmit = () => {
        const {details, changeDetails, editBid, changeBidState, handleCancel, refetchData, inboundingPackages} = this.props
        const {idBid, values} = this.state;
        if (!values.commodity) {
            this.setState({
                isCommodityValid: false,
            });
        } else {
            this.setState({
                isCommodityValid: true,
            });
        }

        if (!values.gender) {
            this.setState({
                isGenderValid: false,
            });
        } else {
            this.setState({
                isGenderValid: true,
            });
        }

        if (!values.pricePerUnit || Number(values.pricePerUnit) === 0) {
            this.setState({
                isPricePerUnitValid: false,
            });
        } else {
            this.setState({
                isPricePerUnitValid: true,
            });
        }

        if (!values.unit) {
            this.setState({
                isUnitValid: false,
            });
        } else {
            this.setState({
                isUnitValid: true,
            });
        }

        if (!idBid &&
            values.commodity &&
            values.gender &&
            values.pricePerUnit &&
            Number(values.pricePerUnit) !== 0 &&
            values.unit) {
            const inboundingPackagesList =
                inboundingPackages &&
                inboundingPackages.length > 0 &&
                inboundingPackages.filter(pack => pack.total === true);
            if (inboundingPackagesList.length > 0) {

                this.setState({
                    openConfirmAddBidModal: true,
                });
            } else if (inboundingPackagesList.length === 0) {
                this.createBid();
            }
        } else if (values.commodity &&
            values.gender &&
            values.pricePerUnit &&
            Number(values.pricePerUnit) !== 0 &&
            values.unit) {
            const resBids = details.bids.map(bid => bid.id === idBid ? this.state.values : bid)
            const values = {
                ...this.state.values,
                ...calculateTotalBids(resBids),
                idPurchase: details.id,
                idBid: idBid,
                brandId: this.state.values && this.state.values.brandId ? this.state.values.brandId.id : null,
            }

            const totalBids = calculateTotalBids(resBids)
            editBid && editBid({
                values: {
                    ...values,
                    articleId: values.articleId.value && !values.articleId.isClear ? values.articleId.value : ''
                },
                resolve: data => {
                    changeDetails({
                        bids: details.bids.map(bid => bid.id === idBid ? data : bid),
                        ...totalBidsToStore(totalBids)
                    })
                    this.setState({
                        loading: false
                    }, () => {
                        notify('success', 'BID successfully edited')
                        handleCancel && handleCancel()
                        refetchData && refetchData()
                        changeBidState({
                            modalCreateBid: false,
                            modalEditBid: false,
                            bidToEdit: null
                        })
                    })
                },
                reject: () => {
                    notify('error', 'Oops something went wrong')
                }
            })
        }


    }

    //TODO FIX LATER
    componentDidMount() {
        const {valuesOptions, getBidsValues, values, changeBidState} = this.props;

        getBidsValues && getBidsValues((commodities, genders) => {
            if (values) {
                const commodity = values.commodity ? commodities.find(item => values.commodity === item.label) : null;
                const gender = values.gender ? genders.find(item => values.gender === item.label) : null;

                if (commodity && commodity.value && gender && gender.value) {
                    this.getRelatedParams('category', commodity.value, gender.value);
                }
            }
        });
        if (values) {
            const commodity = values.commodity && valuesOptions.commodities ? valuesOptions.commodities.find(item => values.commodity === item.label) : null;
            const gender = values.gender && valuesOptions.genders ? valuesOptions.genders.find(item => values.gender === item.label) : null;

            if (commodity && commodity.value && gender && gender.value) {
                this.getRelatedParams('category', commodity.value, gender.value);
            }
        }
    }

    getBrands = (inputValue, callback) => {
        getBrands({
            limit: 20,
            page: 0,
            search: inputValue
        })
            .then(res => {
                callback(res.data.brands.map(item => {
                    return {
                        value: item.name,
                        label: item.name,
                        id: item.id
                    }
                }))
            })
    }

    render() {
        const {
            values,
            categories,
            isPricePerUnitValid,
            isCommodityValid,
            isGenderValid,
            isUnitValid,
        } = this.state
        const {valuesOptions, removeBid, idUBid, isEdit, handleCancel, roles, inboundBegan, inboundBeganAndProgress} = this.props;
        // TODO необходимо переделать, очень много повторяющихся вызовов методов массива

        const sortSupplierOptions = (suppliersOptions) => {
            const sortedOptions = sortArrayByObjectKey(suppliersOptions);
            return sortedOptions;
        };

        return (
            <>
                <tr className="edited-bid-row">
                    <td colSpan={1}>
                        <div className="bid-select-wrap">
                            <AsyncSelect
                                name="articleId"
                                loadOptions={this.getArticles}
                                placeholder="Select Cosmetic ID"
                                defaultOptions={true}
                                value={values.articleId}
                                isDisabled={inboundBeganAndProgress}
                                onChange={obj => {
                                    this.setFieldValue('articleId', obj)
                                }}
                            />
                        </div>
                    </td>
                    <td colSpan={4}>
                        <div className="bid-select-wrap">
                            <Select
                                placeholder="Select commodity"
                                name="commodity"
                                cacheOptions
                                isDisabled={inboundBegan || inboundBeganAndProgress}
                                onChange={obj => {
                                    this.setFieldValue('commodity', obj.label)
                                    this.getRelatedParams('category', obj.value, valuesOptions.genders.find(item => item.label === values.gender) ? valuesOptions.genders.find(item => item.label === values.gender).value : '')
                                }}
                                value={values.commodity && valuesOptions.commodities ? valuesOptions.commodities.find(item => values.commodity === item.label) : null}
                                options={sortSupplierOptions(valuesOptions.commodities)}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                style={{display: isCommodityValid ? 'none' : 'block'}}
                            >
                                Required field
                            </Form.Control.Feedback>
                        </div>
                        <div className="bid-select-wrap">
                            <Select
                                placeholder="Select gender"
                                name="gender"
                                cacheOptions
                                isDisabled={inboundBegan || inboundBeganAndProgress}
                                onChange={obj => {
                                    this.setFieldValue('gender', obj.label)
                                    this.getRelatedParams('category', valuesOptions.commodities.find(item => item.label === values.commodity) ? valuesOptions.commodities.find(item => item.label === values.commodity).value : '', obj.value)
                                }}
                                value={values.gender && valuesOptions.genders ? valuesOptions.genders.find(item => values.gender === item.label) : null}
                                options={sortSupplierOptions(valuesOptions.genders)}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                style={{display: isGenderValid ? 'none' : 'block'}}
                            >
                                Required field
                            </Form.Control.Feedback>
                        </div>
                        <div className="bid-select-wrap">
                            <Select
                                placeholder="Select category"
                                // value={valueCategory ? valueCategory : null}
                                name="category"
                                cacheOptions
                                isDisabled={inboundBegan || inboundBeganAndProgress}
                                value={values.category ? {
                                    label: values.category,
                                    value: values.category
                                } : null}
                                onChange={obj => this.setFieldValue('category', obj.value)}
                                options={sortSupplierOptions(categories)}
                            />
                        </div>
                        <div className="bid-select-wrap">
                            <AsyncSelect
                                placeholder="Select brand"
                                name="brandId"
                                loadOptions={this.getBrands}
                                defaultOptions={true}
                                isDisabled={inboundBegan || inboundBeganAndProgress}
                                value={values.brandId}
                                onChange={obj => {
                                    this.setFieldValue('brandId', obj)
                                }}
                            />
                        </div>
                    </td>
                    <td colSpan={1}>
                        <div className="bid-select-wrap">
                            <Select
                                name="productName"
                                placeholder="Product name"
                                options={sortSupplierOptions(valuesOptions.productName)}
                                isDisabled={inboundBegan || inboundBeganAndProgress}
                                value={values.productName ? {
                                    label: values.productName,
                                    value: values.productName
                                } : null}
                                onChange={obj => {
                                    this.setFieldValue('productName', obj.value)
                                }}
                            />
                        </div>
                    </td>
                    <td colSpan={1}>
                        <div className="bid-select-wrap">
                            <Select
                                name="quality"
                                placeholder="Quality"
                                cacheOptions
                                isDisabled={inboundBeganAndProgress}
                                value={values.quality ? {
                                    label: values.quality,
                                    value: values.quality
                                } : null}
                                onChange={obj => this.setFieldValue('quality', obj.value)}
                                options={sortSupplierOptions(valuesOptions.qualities)}
                            />
                        </div>
                    </td>
                    <td colSpan={1}>
                        <div className="bid-select-wrap">
                            <Select
                                name="channel"
                                placeholder="Channel"
                                cacheOptions
                                isDisabled={inboundBeganAndProgress}
                                value={values.channel ? {
                                    label: values.channel,
                                    value: values.channel
                                } : null}
                                onChange={obj => this.setFieldValue('channel', obj.value)}
                                options={sortSupplierOptions(valuesOptions.channels)}
                            />
                        </div>
                    </td>

                    <td colSpan={2}>
                        <div className="bid-select-wrap">

                            {findPermInArray(roles, permFinanceInfo) && (
                                <>
                                    <Form.Control
                                        placeholder="Negotiated €/Unit"
                                        name="pricePerUnit"
                                        disabled={inboundBeganAndProgress}
                                        onChange={e => this.setFieldValue('pricePerUnit', e.target.value)}
                                        onBlur={e => this.setFieldValue('pricePerUnit', formatPriceWithEmptyString(e.target.value))}
                                        value={values.pricePerUnit}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        style={{
                                            display: isPricePerUnitValid ? 'none' : 'block',
                                            marginBottom: '-15px',
                                        }}
                                    >
                                        Required field
                                    </Form.Control.Feedback>
                                </>
                            )}
                        </div>
                        <div className="bid-select-wrap">

                            <Select
                                name="unit"
                                placeholder="Unit"
                                isDisabled={inboundBeganAndProgress}
                                value={values.unit ? {
                                    label: values.unit,
                                    value: values.unit
                                } : null}
                                onChange={obj => {
                                    this.setFieldValue('unit', obj.value)
                                }}
                                options={sortSupplierOptions(valuesOptions.units)}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                style={{display: isUnitValid ? 'none' : 'block', marginBottom: '-15px',}}
                            >
                                Required field
                            </Form.Control.Feedback>

                        </div>
                    </td>

                    <td colSpan={3}>
                        <div className="bid-select-wrap">
                            <Form.Control
                                placeholder="Pcs"
                                name="quantity"
                                disabled={inboundBeganAndProgress}
                                onChange={e => {
                                    this.setFieldValue('quantity', e.target.value)
                                }}
                                onBlur={e => this.setFieldValue('quantity', formatIntegerWithEmptyString(e.target.value))}
                                value={values.quantity}
                            />
                        </div>
                        <div className="bid-select-wrap">
                            <Form.Control
                                onChange={e => this.setFieldValue('packages', e.target.value)}
                                placeholder="Packs"
                                name="packages"
                                disabled={inboundBeganAndProgress}
                                onBlur={e => this.setFieldValue('packages', formatIntegerWithEmptyString(e.target.value))}
                                value={values.packages}
                            />
                        </div>
                        <div className="bid-select-wrap">
                            <Form.Control
                                placeholder="Pallets"
                                onChange={e => this.setFieldValue('pallets', e.target.value)}
                                name="pallets"
                                disabled={inboundBeganAndProgress}
                                onBlur={e => this.setFieldValue('pallets', formatIntegerWithEmptyString(e.target.value))}
                                value={values.pallets}
                            />
                        </div>
                    </td>

                    <td colSpan={2}>
                        <div className="bid-select-wrap">
                            <Form.Control
                                placeholder="Gross kg"
                                onChange={e => this.setFieldValue('grossWeight', e.target.value)}
                                name="grossWeight"
                                disabled={inboundBeganAndProgress}
                                onBlur={e => this.setFieldValue('grossWeight', formatPriceWithEmptyString(e.target.value))}
                                value={values.grossWeight}
                            />
                        </div>
                        <div className="bid-select-wrap">
                            <Form.Control
                                placeholder="Net kg"
                                name="netWeight"
                                disabled={inboundBeganAndProgress}
                                onChange={e => this.setFieldValue('netWeight', e.target.value)}
                                onBlur={e => this.setFieldValue('netWeight', formatPriceWithEmptyString(e.target.value))}
                                value={values.netWeight}
                            />
                        </div>
                    </td>
                    <td colSpan={3}>
                        <div className="bid-select-wrap">
                            <Form.Control
                                placeholder="Actual pcs/kg"
                                name="actualQuantity"
                                onChange={e => {
                                    this.setFieldValue('actualQuantity', e.target.value)
                                }}
                                onBlur={e => this.setFieldValue('actualQuantity', formatIntegerWithEmptyString(e.target.value))}
                                value={values.actualQuantity}
                            />
                        </div>
                        <div className="bid-select-wrap">
                            {findPermInArray(roles, permFinanceInfo) && (
                                <>
                                    <Form.Control
                                        placeholder="Credit note"
                                        name="creditNote"
                                        onChange={e => this.setFieldValue('creditNote', e.target.value)}
                                        onBlur={e => this.setFieldValue('creditNote', formatPriceWithEmptyString(e.target.value))}
                                        value={values.creditNote}
                                    />
                                </>
                            )}
                        </div>
                    </td>
                    <td colSpan={3}>
                        <div className="bid-select-wrap">
                            {findPermInArray(roles, permFinanceInfo) && <Form.Control
                                placeholder="Total negotiated"
                                name="totalPrice"
                                disabled={inboundBeganAndProgress}
                                onChange={e => this.setFieldValue('totalPrice', e.target.value)}
                                onBlur={e => this.setFieldValue('totalPrice', formatPriceWithEmptyString(e.target.value))}
                                value={values.totalPrice}
                            />
                            }
                        </div>

                        <div className="bid-select-wrap">
                            {findPermInArray(roles, permFinanceInfo) && <Form.Control
                                placeholder="Discount"
                                name="percent"
                                disabled={inboundBeganAndProgress}
                                onChange={e => this.setFieldValue('percent', e.target.value)}
                                onBlur={e => this.setFieldValue('percent', formatPercentWithEmptyString(e.target.value))}
                                value={values.percent}
                            />
                            }
                        </div>
                    </td>
                    <td>
                        {findPermInArray(roles, permEditInfo) && <div className="wrap-actions">
                            <Tooltip
                                text="Save"
                            >
                                <button onClick={e => {
                                    this.handleSubmit(e)
                                }} className="action-row-button">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"/>
                                    </svg>
                                </button>
                            </Tooltip>
                            <Tooltip
                                text="Cancel"
                            >
                                <button onClick={() => {
                                    if (!isEdit)
                                        removeBid(idUBid)
                                    else
                                        handleCancel()
                                }} className="action-row-button">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="#000000"
                                              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
                                    </svg>
                                </button>
                            </Tooltip>
                        </div>
                        }
                    </td>
                </tr>
                <CustomModalWithChildren
                    open={this.state.openConfirmAddBidModal}
                    handleClose={() => this.handleCloseConfirmAddBidModal()}
                    handleCancel={() => this.handleCloseConfirmAddBidModal()}
                    handleConfirm={() => this.handleConfirmAddBidModal()}
                >
                    <div className="wrap-text">
                        <p><span className="warn-text">Caution!</span></p>
                        <p>Adding additional BID leads to data loss, are you sure you want to add it?</p>
                    </div>
                </CustomModalWithChildren>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        valuesOptions: state.poDetailsReducer.valuesOptions,
        details: state.purchaseOrdersReducer.detailsPurchase,
        isLoading: state.poDetailsReducer.isLoadingBidsValues,
        roles: state.rolesReducer.roles,
        inboundingPackages: state.inboundingReportReducer.packages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBidsValues: (callback) => dispatch(getBidsValues(callback)),
        createBid: obj => dispatch(createBid(obj)),
        changeDetails: obj => dispatch(setDetailsPurchaseAction(obj)),
        removeBid: id => dispatch(removeUnconfirmedBid(id)),
        changeBidState: state => dispatch(changeState(state)),
        editBid: data => dispatch(editBid(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditedRow)